<template>
  <el-container>
      <el-aside>
        <SideNav></SideNav>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
</template>

<script>
import SideNav from "../components/SideNav.vue";
export default {
  name: "Main",
  components: {
    SideNav,
  },

  data() {
    return {};
  },

  methods: {
   
  },

  created() {
    this.$store.dispatch("auth/loadAuthInfo");
  },

  mounted() {},

  beforeDestroy() {},
};
</script>
<style>
</style>
