<template>
  <div id="side-nav">
    <el-menu
      v-if="authInfo != null"
      class="el-menu-vertical-demo"
      text-color="#000"
      active-text-color="#123"
      router="true"
    >
      <el-submenu>
        <template slot="title">
          用户：<el-tag>{{ authInfo.nickName }}</el-tag>
          
        </template>
        <el-menu-item-group>
          <el-menu-item index="/info">信息</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item @click="logout">退出</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <template v-for="(route, index) in routes">
        <el-submenu
          :key="route.name"
          v-if="route.inNav && checkPermission(route.permissions)"
          :index="index"
        >
          <span slot="title">{{ route.name }}</span>
          <template v-for="cItem in route.children">
            <el-menu-item-group :key="cItem.name" v-if="checkPermission(cItem.permissions) && checkAuTypes(cItem.auTypes)">
              <el-menu-item :index="cItem.path">{{ cItem.name }}</el-menu-item>
            </el-menu-item-group>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import routes from "../router/routes";
export default {
  name: "SideNav",
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },

    checkRole(role, allRoles) {
      if (role == "ROOT") {
        return true;
      }
      if (allRoles == null || allRoles.length <= 0) {
        return false;
      }
      return allRoles.includes(role);
    },

    checkPermission(allow) {
      if(allow == null || allow.length <= 0){
        return true;
      }

      if (this.authInfo.permissions == null) {
        return false;
      }

      for (var i = 0; i < allow.length; i++) {
        if (this.authInfo.permissions.includes(allow[i])) {
          return true;
        }
      }

      return false;
    },

    checkAuTypes(allow) {
      if(allow == null || allow.length <= 0){
        return true;
      }

      if (this.authInfo.auType == null) {
        return false;
      }

      for (var i = 0; i < allow.length; i++) {
        if (this.authInfo.auType == allow[i]) {
          return true;
        }
      }

      return false;
    },
  },
  data: () => {
    return {
      routes,
    };
  },

  created() {},

  computed: {
    authInfo: function () {
      return this.$store.state.auth.authInfo;
    },
  },
};
</script>

<style scoped>
</style>